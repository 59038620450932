.main {
  width: 100%;
  height: calc(100vh - 252px);
  background-color: #fef6ed;
}

.wrap{
  width: 100%;
  height: 100%;
}

.landingScreen{
  overflow-y: auto;
  position: relative;
  background-image: url("../../images/neuroNotes/landing_page_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 20px 20px -15px rgba($color: #000000, $alpha: 1);
}

.topBorderEffect {
  position: fixed;
  width: 100%;
  height: 4px;
  background: rgba($color: #d4bb94, $alpha: 1);
  box-shadow: inset 0px 20px 15px -15px rgba($color: #000000, $alpha: 0.7);
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  padding-top: 10px;
  box-sizing: content-box;
}

.logoText {
  width: 100%;
  height: 46px;
  background: url("../../images/neuroNotes/Logo_Neuronotes.svg") no-repeat center;
  background-size: contain;
}

.textWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-family: 'Lora', serif;
  font-weight: 500;
  font-size: 32px;
  color: #161616;
  text-transform: uppercase;
  margin-top: 45px;
}

.subTitle {
  text-align: center;
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 23px;
  color: #161616;  
  margin-top: 20px;

}

.comingSoonWrap {
  margin-top: 70px;
  text-align: center;
  span {
    font-family: 'Montserrat', serif;
    font-weight: 600;
    font-size: 32px;
    color: #161616;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1440px) {

  .landingScreen{
    background-size: cover;
  }
}


@media screen and (max-width: 900px) {

}