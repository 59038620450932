.main {
  height: 186px;
}

.wrap{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background: #000000;
  align-items: center;
}

.bgLogo {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: -200px;
  height: 186px;
  overflow: hidden;
  transform: scale(1);
}

.logoSetClipPath {
  opacity: 0.18;
}

.logoBgColor {
  fill: #ffefc3;
}

.logoTextColor {
  fill: #fff;
}

.bgRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 450px;
  height: 100%;
  background: url("../../images/footer/Element_footer.png") no-repeat;
}

.navWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 700px;
  box-sizing: content-box;
}

.column {
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.squarelessBtn {
  height: fit-content;
  padding: 0 20px;
  margin: 20px 10px;
  cursor: pointer;
  text-decoration: none;

  span {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 500;
    color: #ffffff;
    font-size: 20px;
    padding: 4px 8px;
  }

  &:hover span{
    text-shadow: 0px 0px 15px #00ccff;
    color: #00ccff;
  }
}

@media screen and (max-width: 1440px) {
  
  .bgLogo {
    display: none;
  }

  .wrap{
    justify-content: flex-end;
  }

  .navWrap {
    width: calc(100% - 450px);
    padding: 0 300px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


@media screen and (max-width: 900px) {

  .wrap{
    justify-content: flex-start;
  }

  .navWrap {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}