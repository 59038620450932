.main {
  width: 100%;
  height: 66px;
  user-select: none;
  overflow: hidden;
}

.wrap{
  width: 100%;
  height: 100%;
  background: #201e1d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  transform: scale(.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.SetRectangle {
  fill: #161616;
  opacity: 0.95;
}

.clipPath1 {
  opacity: 0.09;
}

.setFill1 {
  fill: #ffefc3;
}

.clipPath2 {
  opacity: 0.17;
}

.setFill2 {
  fill: #fff;
}
